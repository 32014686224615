/* eslint-disable react/prop-types */
import * as React from "react"
import Accordeons from "src/components/accordeons"
import Footer from "src/components/footer"
import ImageGrid from "src/components/image-grid"
import Layout from "src/components/layout"
import Seo from "src/components/seo"
import footerData from "src/data/global/footer"

import ServiceHeader from "../components/service-header"

const ServicePage = ({ pageContext }) => (
  <Layout>
    <Seo title={pageContext.meta.title} description={pageContext.meta.description} url={pageContext.meta.path} />
    <ServiceHeader data={pageContext.atf} />
    <Accordeons data={pageContext.services} />
    {
      pageContext.projects
      && <ImageGrid data={pageContext.projects} />
    }
    <Footer data={footerData} hasBackground={!pageContext.projects} />
  </Layout>
)

export default ServicePage
