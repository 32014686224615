import classNames from "classnames"
import PropTypes from "prop-types"
import * as React from "react"
import ReactHtmlParser from "react-html-parser"
import DsImage from "src/components/ds-image"

import { cServiceHeader, cServiceHeaderBackground, cServiceTitle } from "./styles.module.scss"

const ServiceHeader = ({ data }) => {
  const headerInnerClasses = classNames({
    [cServiceHeader]: true,
  })
  const headerOuterClasses = classNames({
    [cServiceHeaderBackground]: data.hasBackground,
  })

  return (
    <div className={headerOuterClasses}>
      <div className={headerInnerClasses}>
        <h1 className={cServiceTitle}>{ReactHtmlParser(data.heading)}</h1>
        <DsImage src={data.image} alt={data.alt} />
      </div>
    </div>
  )
}

ServiceHeader.defaultProps = {
  data: {
    hasBackground: false,
    heading: "",
    image: "",
    alt: "",
  }
}

ServiceHeader.propTypes = {
  data: PropTypes.shape({
    hasBackground: PropTypes.bool,
    heading: PropTypes.string,
    image: PropTypes.string,
    alt: PropTypes.string
  })
}

export default ServiceHeader
