import classNames from "classnames"
import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import ReactHtmlParser from "react-html-parser"
import Arrow from "src/assets/images/arrow.inline.svg"

import { cAccordeonArrow, cAccordeonArrowOpen, cAccordeonArrowRight, cAccordeonButton, cAccordeonLink, cAccordeonP, cAccordeonText, cAccordeonWrapper } from "./styles.module.scss"

import "src/styles/animations.scss"

function useDelayUnmount(isMounted, delayTime) {
  const [showDiv, setShowDiv] = useState(false)
  useEffect(() => {
    let timeoutId
    if (isMounted && !showDiv) {
      timeoutId = setTimeout(() => setShowDiv(true), delayTime)
    } else if (!isMounted && showDiv) {
      timeoutId = setTimeout(() => setShowDiv(false), delayTime)
    }
    return () => clearTimeout(timeoutId) // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
  }, [isMounted, delayTime, showDiv])
  return showDiv
}

const Accordeon = ({ title, text, link, readMore, isAgb }) => {
  const [isAccOpen, setIsAccOpen] = useState(false)
  const showAccordeon = useDelayUnmount(isAccOpen, 200)

  const mountedStyle = { animation: "inAnimation 200ms ease-in" }
  const unmountedStyle = { animation: "outAnimation 200ms ease-out" }

  const cAccordeonArrowClasses = classNames({
    [cAccordeonArrow]: true,
    [cAccordeonArrowOpen]: isAccOpen,
    [cAccordeonArrowRight]: !!link || !!isAgb,
  })

  const cAccordeonTextClasses = classNames({
    [cAccordeonText]: true,
  })

  const cvLink = useStaticQuery(graphql`
    {
      allFile(filter: {extension: {eq: "pdf"}, name: {eq: "lebenslauf"}}) {
        edges {
          node {
            id
            publicURL
            name
          }
        }
      }
    }
  `)

  return (
    <div className={cAccordeonWrapper}>
      {!!isAgb && (
        <a href={cvLink.allFile.edges[0].node.publicURL} className={cAccordeonButton} target="_blank" rel="noopener noreferrer">
          <strong>PDF Lebenslauf herunterladen</strong>
          <Arrow className={cAccordeonArrowClasses} />
        </a>
      )}
      {!!link && (
        <Link to={link} className={cAccordeonButton}>
          <strong>{title}</strong>
          <Arrow className={cAccordeonArrowClasses} />
        </Link>
      )}
      {!link && !isAgb && (
        <>
          <button
            type="button"
            onClick={() => setIsAccOpen(!isAccOpen)}
            className={cAccordeonButton}
            aria-label="Toggle Accordeon"
          >
            <strong>{title}</strong>
            <Arrow className={cAccordeonArrowClasses} />
          </button>
          {showAccordeon && !!text && (
            <div
              className={cAccordeonTextClasses}
              style={isAccOpen ? mountedStyle : unmountedStyle}
            >
              <p className={cAccordeonP}>{ReactHtmlParser(text)}</p>
              {readMore && (
                <Link to={readMore} className={cAccordeonLink}>
                  Mehr erfahren
                </Link>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}

Accordeon.defaultProps = {
  text: "",
  link: "",
  readMore: "",
  isAgb: false
}

Accordeon.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  link: PropTypes.string,
  readMore: PropTypes.string,
  isAgb: PropTypes.bool,
}

export default Accordeon
