import classNames from "classnames"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import * as React from "react"
import ReactHtmlParser from "react-html-parser"
import DsImage from "src/components/ds-image"
import { v4 as uuid } from "uuid"

import { cImageGridInner, cImageGridSection, cImageGridSectionBackground } from "./styles.module.scss"

const ImageGrid = ({ data }) => {
  const sectionClasses = classNames({
    [cImageGridSection]: true,
    [cImageGridSectionBackground]: data.hasBackground,
  })

  return (
    <section className={sectionClasses}>
      {data.heading && (
        <div className={cImageGridInner}>
          <h2>{ReactHtmlParser(data.heading)}</h2>
        </div>
      )}
      <div className={cImageGridInner}>
        {data.images.map(img =>
          img.link ? (
            <Link to={img.link} key={uuid()} aria-label={`Preview Image of ${img.link}`}>
              <DsImage src={img.image} alt={img.alt} />
            </Link>
          ) : (
            <DsImage
              src={img.image}
              alt="Gatsby Docs are awesome"
              key={uuid()}
              hasModal={img.modal}
            />
          )
        )}
      </div>
    </section>
  )
}

ImageGrid.defaultProps = {
  data: {
    hasBackground: false,
    heading: '',
    images: [{
      link: '',
      image: '',
      alt: '',
    }]
  }
}

ImageGrid.propTypes = {
  data: PropTypes.shape({
    hasBackground: PropTypes.bool,
    heading: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string,
      image: PropTypes.string,
      alt: PropTypes.string,
    }))
  })
}

export default ImageGrid
