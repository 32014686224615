import classNames from "classnames"
import PropTypes from "prop-types"
import * as React from "react"
import ReactHtmlParser from "react-html-parser"
import Accordeon from "src/components/accordeon"
import { v4 as uuid } from "uuid"

import { cAboutAccordeonsCol, cAboutInner, cAboutSection, cAboutSectionBackground, cAboutTextCol } from "./styles.module.scss"

const Accordeons = ({ data }) => {
  const sectionClasses = classNames({
    [cAboutSection]: true,
    [cAboutSectionBackground]: data.hasBackground,
  })

  return (
    <section className={sectionClasses}>
      <div className={cAboutInner}>
        <div className={cAboutTextCol}>
          {data.subHeading && <h3>{ReactHtmlParser(data.subHeading)}</h3>}
          {data.heading && <h1>{ReactHtmlParser(data.heading)}</h1>}
          {data.text && <div>{ReactHtmlParser(data.text)}</div>}
        </div>
        <div className={cAboutAccordeonsCol}>
          {data.accordeons.map(e => (
            <Accordeon
              key={uuid()}
              title={e.title}
              text={e.text}
              link={e.link}
              readMore={e.readMore}
              isAgb={!!e.isAgb}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

Accordeons.defaultProps = {
  data: {
    subHeading: '',
    heading: '',
    text: '',
    hasBackground: false,
    accordeons: []
  }
}

Accordeons.propTypes = {
  data: PropTypes.shape({
    subHeading: PropTypes.string,
    heading: PropTypes.string,
    text: PropTypes.string,
    hasBackground: PropTypes.bool,
    accordeons: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      link: PropTypes.string,
      readMore: PropTypes.string,
      isAgb: PropTypes.bool,
    }))
  })
}

export default Accordeons
